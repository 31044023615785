@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
img {
  max-height: 100%;
  max-width: 100%;
}
.background-logo {
  background-image: url("./assets/png/logo-valgroup.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.acoes {
  background-image: url("./assets/png/acoes.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#background-geral {
  background-image: url("./assets/png/background-geral.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}
#portugues {
  background-image: url("./assets/png/idioma-portugues.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#ingles {
  background-image: url("./assets/png/idioma-usa.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#espanhol {
  background-image: url("./assets/png/idioma-esp.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.bg-modules {
  background-image: url("./assets/png/bg-modules.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.bg-sweet-loading.bg-sweet-loading.bg-sweet-loading {
  background-color: rgba(0, 0, 0, 0.6) !important;
}
@keyframes fade {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 1;
  }
}

.elem {
  animation: moveToLeft 0.7s ease;
  animation-delay: 250ms;
  animation-fill-mode: forwards;
}

@keyframes moveToLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0px);
  }
}

@media (max-width: 414px) {
  html {
    font-size: 14px;
  }
}