.button {
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-out;
}

.spinner {
  display: block;
  width: 34px;
  height: 34px;
  position: absolute;
  top: 8px;
  left: calc(50% - 17px);
  background: transparent;
  box-sizing: border-box;
  border-top: 4px solid white;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-radius: 100%;
  animation: spin 0.6s ease-out infinite;
}

.loading {
  min-width: 50px !important;
  min-height: 50px !important;
  border-radius: 100% !important;
  padding: 0 !important;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
